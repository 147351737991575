(function () {
  var $element = document.querySelector('.testimonial-container.crm-1stassociated');

  if($element === null) {
    return;
  }

  var region = $element.getAttribute('data-region');
  var websiteId = $element.getAttribute('data-website');
  var domain = process.env.MIX_1STASSOCIATED_DOMAIN;
  var url = domain + '/api/testimonials/' + region;
  
  if (websiteId) {
    url += '?website_id=' + websiteId;
  }

  function getElement(e) {
    var element = ["<li>"];
    if (e.headline !== null && e.headline.length > 0) {
      element.push("<h5>" + e.headline + "</h5>");
    }
    element.push('<div class="bubble">');
    // NOTE: Images have been removed from the API response
    // if (e.images && e.images.length > 0) {
    //   element.push("<div class='images'>");
    //   e.images.forEach(image => element.push('<img src="' + image + '" class="testimonial-image">'));
    //   element.push("</div>");
    // }
    element.push('<p>' + e.text + '</p>');
    element.push('<p align="right" class="testimonial_name">' + e.name + '</p>');
/*
    if (e.notes !== null && e.notes.length > 0) {
      element.push('<p class="testimonial-notes">' + e.notes + '</p>');
    }
*/
    element.push('</div>');
    element.push('</li>');
    return element.join("\n");
  }

  fetch(url)
    .then(response => response.json())
    .then(response => {
      let id = `gem-crm-testimonials-embed`;
      $element.innerHTML += `<ul id="${id}"></ul>`;
      var testimonials = document.getElementById(id);
      testimonials.innerHTML += `<link rel="stylesheet" href="${domain}/css/testimonials.css" type="text/css" />`;

      response.forEach(element => testimonials.innerHTML += getElement(element));
    });
})();
